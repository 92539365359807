import React, { useEffect, useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer
} from 'recharts';

function GoldPrice() {
  const [goldData, setGoldData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
  const day = String(currentDate.getDate()).padStart(2, '0'); // Add leading zero if needed
  const formattedDate = `${year}-${month}-${day}`;

  useEffect(() => {
    // Fetch gold price data based on selected filter criteria
    const fetchData = async () => {
      try {
        let url = '';
        let bodyData = {};

        switch (selectedFilter) {
          case 'All':
            url = 'https://crm.careholding.it/ws/Call/?Cat=Oro&met=GetPrezziOroMediClientePublic&np=1';
            bodyData = { 'Piano': '' };
            break;
          case 'LastYear':
            url = 'https://crm.careholding.it/ws/Call/?Cat=Oro&met=GetQuotazioniLastYearOroPublic&np=1';
            bodyData = { 'StartDate': formattedDate };
            break;
          case 'LastMonth':
            url = 'https://crm.careholding.it/ws/Call/?Cat=Oro&met=GetQuotazioniMonthOroPublic&np=2';
            bodyData = { 'Anno': year, 'Mese': month };
            break;
          default:
            break;
        }

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        });

        let modifiedData = await response.json();
        modifiedData = modifiedData.reverse();

        // Adjust data mapping based on selected filter
        switch (selectedFilter) {
          case 'All':
            modifiedData = modifiedData.map(item => {
              const year = Math.floor(item.DataRiferimento / 100);
              const month = String(item.DataRiferimento % 100).padStart(2, '0');
              const formattedDate = `${month}-${year}`;

              return {
                date: formattedDate,
                price: item.PrezzoMedioFixing
              };
            });
            break;
          case 'LastYear':
            modifiedData = modifiedData.map(item => {
              const dateObj = new Date(item.DataRiferimento);
              const day = String(dateObj.getDate()).padStart(2, '0');
              const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
              const year = dateObj.getFullYear();

              return {
                date: `${day}-${month}-${year}`,
                price: item.PrezzoEuro
              };
            });
            break;
          case 'LastMonth':
            modifiedData = modifiedData.map(item => {
              const dateObj = new Date(item.DataRiferimento);
              const day = String(dateObj.getDate()).padStart(2, '0');
              const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
              const year = dateObj.getFullYear();

              return {
                date: `${day}-${month}-${year}`,
                price: item.EurPm
              };
            });
            break;
          default:
            break;
        }

        modifiedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        setGoldData(modifiedData);
      } catch (error) {
        console.error('Error fetching gold price data:', error);
      }
    };

    fetchData();

  }, [selectedFilter, year, month, formattedDate]); // Include year and month in dependency array

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <div>
      <div className='mb-5'>
        <button
          type="button"
          onClick={() => handleFilterChange('All')}
          className="rounded px-2 py-1 text-xs mx-2 font-semibold hover:text-slate-200 shadow-sm ring-1 ring-inset ring-gray-500 bg-gray-50 hover:bg-transparent text-black"
        >
          All
        </button>
        <button
          type="button"
          onClick={() => handleFilterChange('LastYear')}
          className="rounded px-2 py-1 text-xs mx-2 font-semibold hover:text-slate-200 shadow-sm ring-1 ring-inset ring-gray-500 bg-gray-50 hover:bg-transparent text-black"
        >
          Last Year
        </button>
        <button
          type="button"
          onClick={() => handleFilterChange('LastMonth')}
          className="rounded px-2 py-1 text-xs mx-2 font-semibold hover:text-slate-200 shadow-sm ring-1 ring-inset ring-gray-500 bg-gray-50 hover:bg-transparent text-black"
        >
          Last Month
        </button>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={goldData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" stroke="white" />
          <YAxis stroke="white" />
          <Tooltip
              formatter={(value) => [`€${value} / g`, 'Prezzo']}
              labelFormatter={(label) => `Data: ${label}`}
              itemStyle={{ color: '#000' }} // Custom color for the value
              labelStyle={{ color: '#000' }} // Custom color for the label
            />          
          <Line type="monotone" dataKey="price" stroke="gold" dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default GoldPrice;
