import React, { useEffect, useRef, useState } from 'react';

function TradingViewWidget() {
  const container = useRef();
  const widgetVisibleRef = useRef(false); // Use useRef to track visibility state
  const [widgetVisible, setWidgetVisible] = useState(true); 

  useEffect(() => {
    if (!widgetVisibleRef.current && widgetVisible) { // Only append the script if the widget is visible and the effect hasn't run before
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "width": "100%",
          "height": "500",
          "symbol": "FX_IDC:XAUEURG",
          "interval": "D",
          "timezone": "Europe/Rome",
          "theme": "dark",
          "style": "1",
          "locale": "it",
          "enable_publishing": false,
          "backgroundColor": "#272727",
          "hide_top_toolbar": true,
          "allow_symbol_change": false,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;

      container.current.appendChild(script);
      widgetVisibleRef.current = true; // Set the ref to true to indicate that the effect has been executed
    }
    
    return () => {
        // Funzione di cleanup
    };

  }, [widgetVisible]); // Only run the effect when widgetVisible changes

  return (
    <>
      {widgetVisible && <div className="tradingview-widget-container" ref={container}></div>}
    </>
  );
}

export default TradingViewWidget;
