import { faInfoCircle, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

function TradingViewSingleQuoteWidget() {
  useEffect(() => {
    const container = document.getElementById('tradingview-widget-container1');

    if (container && !container.querySelector('script')) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbol: 'FX_IDC:XAUEURG',
        width: '100%',
        isTransparent: true,
        colorTheme: 'dark',
        locale: 'it'
      });

      container.appendChild(script);
    }

    return () => {
    };
  }, []);

  const [showInfo, setShowInfo] = useState(false);
  const [modalFade, setModalFade] = useState('fadeIn');

  const handleOpenSpotPopup = () => {
    setShowInfo(true);
    setModalFade('fadeIn');
  };

  const handleCloseSpotPopup = () => {
    setModalFade('fadeOut');
    setTimeout(() => {
      setShowInfo(false);
    }, 1500);
  };

  return (
    <>
      <div className='grid grid-cols-12 gap-4 mt-0'>
        <div className='col-span-12 flex flex-col items-center md:items-start md:ms-5'>
          <p className='uppercase text-slate-50 font-semibold text-2xl text-static-gold'>prezzo spot          
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="text-gray-400 text-sm cursor-pointer transition-all duration-500 mb-3 ml-2"
              onClick={handleOpenSpotPopup}
            />
          </p>        
        </div>
        <div className='col-span-12'>
          <div id="tradingview-widget-container1" className=''>
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright h-0">
              <a href="https://it.tradingview.com/" rel="noopener noreferrer" target="_blank">
                <span className="text-transparent">Segui tutti i mercati su TradingView</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {showInfo && (
        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center text-center glassmorphism ${modalFade}`}>
          <div className={`bg-transparent p-8 relative w-auto md:w-1/3`}>
            <span className='absolute top-0 right-0 cursor-pointer p-3' onClick={handleCloseSpotPopup}>
              <FontAwesomeIcon
                icon={faX}
                className="text-slate-50 opacity-75"
              />
            </span>
            <p className={`text-slate-50 text-justify font-semibold text-xl mb-6 mt-10`}>Il prezzo spot dell'oro nel mercato forex è il prezzo corrente per la consegna immediata dell'oro, determinato dalla domanda e dall'offerta. È influenzato da fattori come domanda e offerta, valore del dollaro e sentiment del mercato. Gli operatori cercano di prevedere i movimenti futuri del prezzo utilizzando analisi tecnica e fondamentale.</p>
          </div>
        </div>
      )}

    </>
  );
}

export default TradingViewSingleQuoteWidget;
