import React, { useEffect, useState } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown, faInfoCircle, faX } from '@fortawesome/free-solid-svg-icons';

function GoldPriceChart() {
  const [fixing, setFixing] = useState("");
  const [bullBear, setBullBear] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [modalFade, setModalFade] = useState('fadeIn');


  useEffect(() => {
    axios.post("https://newgest.careholding.it/principale.asmx/getprezzoORO", {}, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      const [prezzo, variazione] = response.data.d.split('#');
      setBullBear(variazione);
      setFixing(prezzo);
    })
    .catch(error => {
      console.error("An error occurred:", error);
    });

    return () => {
      // Funzione di cleanup
    };
  
  }, []);

  const handleOpenFixingPopup = () => {
    setShowInfo(true);
    setModalFade('fadeIn');
  }

  const handleCloseFixingPopup = () => {
    setModalFade('fadeOut');
    setTimeout(() => {
      setShowInfo(false);
    }, 1500);
  };

  return (
    <div>
      <div className="flex items-center justify-center mb-3">
        <p className='uppercase text-slate-50 font-semibold text-2xl text-static-gold'>fixing del giorno</p>        
        <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-gray-400 text-sm ml-1 cursor-pointer transition-all duration-500 mb-auto"
            onClick={handleOpenFixingPopup}
        />
      </div>
      <div className="flex text-start items-center justify-start mb-3">
        <svg className="w-12 h-12 mr-2" viewBox="0 0 79 123" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="icon-logo-bg" d="M79 0H0v122.953h79V0z"></path>
          <path d="M54.837 56.024a40.27 40.27 0 00-6.751-22.386 23.096 23.096 0 01-.119 44.89 39.796 39.796 0 006.87-22.504zM16.112 96.172h1.895v13.858h6.04l-.237 1.54h-7.698V96.173zM29.847 97.712h1.659a2.369 2.369 0 011.776 2.487 4.264 4.264 0 01-.237 1.185c-.474.947-1.066 1.303-2.724 1.303h-2.724v-4.975h2.25zM25.82 111.57h4.027c2.25 0 2.724 0 3.553-.356a4.14 4.14 0 002.606-3.908 4.26 4.26 0 00-1.421-3.08 4.63 4.63 0 00-2.014-.829 3.55 3.55 0 002.725-3.434 3.672 3.672 0 00-2.606-3.435c-.83-.356-1.54-.356-4.62-.356h-2.25v15.398zm4.501-7.225h1.895a2.604 2.604 0 011.777 2.724c.034.447-.047.896-.237 1.303-.474 1.184-1.185 1.54-3.08 1.54h-3.08v-5.567h2.725zM39.445 96.172h2.605l2.843 10.068.592 2.724c.145-.88.343-1.75.592-2.606l2.843-10.186h2.724l1.421 15.398H51.17l-.947-11.252c0-1.066-.119-2.724-.119-2.724-.113.847-.312 1.68-.592 2.487l-3.316 11.489h-1.54l-3.08-10.897c-.236-1.184-.473-2.013-.71-3.08-.237-1.065-.118 2.014-.237 2.962L39.8 111.57h-1.777l1.422-15.397zM59.694 100.081l.829-2.25.71 2.368c.356 1.303 1.659 5.093 1.659 5.093h-4.857s1.54-4.974 1.659-5.211zm-.356-3.909l-5.093 15.398h1.895l1.422-4.738h5.803l1.422 4.738h2.131l-5.092-15.397h-2.488zM46.07 33.755l1.777 4.264A19.308 19.308 0 0022.164 52.89a19.306 19.306 0 0019.288 22.556 20.729 20.729 0 006.04-.947l-1.54 3.79-4.382 10.778-4.5-10.778-8.173 8.29V74.855l-10.66 4.5 4.383-10.659H11.012l8.291-8.172-10.778-4.501 10.778-4.382-8.29-8.173H22.62L18.237 32.69l10.66 4.5V25.584l8.172 8.172 4.501-10.66 4.5 10.66z" fill="#fff"></path>
        </svg>
        <div>
          <p className="text-slate-50 font-semibold">LBMA</p>
          <p className="text-gray-400 text-sm">GOLD / EURO GRAM</p>
        </div>
      </div>

      <div className="flex items-center justify-start ms-3">
        <span className="font-semibold text-slate-50 text-2xl me-4 sm:me-10 transition-all duration-500">&euro;&nbsp;{fixing}</span>
        <FontAwesomeIcon
          className={`text-2xl ${bullBear >= 0 ? 'text-green-600' : 'text-rose-600'} transition-all duration-500`}
          icon={bullBear >= 0 ? faArrowCircleUp : faArrowCircleDown}
        />
        <span className={`font-semibold ms-1 text-2xl ${bullBear >= 0 ? 'text-green-600' : 'text-rose-600'} transition-all duration-500`}>{bullBear}&nbsp;%</span>
      </div>
      {showInfo && (
        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center text-center glassmorphism ${modalFade}`}>
          <div className={`bg-transparent p-8 relative w-auto md:w-1/3`}>
            <span className='absolute top-0 right-0 cursor-pointer p-3' onClick={handleCloseFixingPopup}>
              <FontAwesomeIcon
                icon={faX}
                className="text-slate-50 opacity-75"
              />
            </span>
            <p className={`text-slate-50 text-justify font-semibold text-xl mb-6 mt-10`}>Il fixing LBMA dell'oro è un processo quotidiano utilizzato per stabilire il prezzo di riferimento dell'oro due volte al giorno, una volta al mattino e una volta al pomeriggio. Il processo coinvolge un gruppo di banche membri della LBMA (London Bullion Market Association) che si riuniscono e concordano su un prezzo di riferimento per l'oro, noto come "fixing".</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default GoldPriceChart;
