import React, { useEffect } from 'react';

function Footer() {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [
          {
            description: 'GOLD EURO GRAM',
            proName: 'FX_IDC:XAUEURG'
          },
          {
            description: 'GOLD GRAM / BITCOIN',
            proName: 'TVC:XAUBTCG'
          },
          {
            description: 'GOLD GRAM / ETHEREUM',
            proName: 'TVC:XAUETHG'
          },
          {
            description: 'GOLD / US DOLLAR GRAM',
            proName: 'FX_IDC:XAUUSDG'
          },
          {
            description: 'GOLD OZ / U.S. DOLLAR',
            proName: 'FOREXCOM:XAUUSD'
          }
        ],
        showSymbolLogo: true,
        isTransparent: true,
        displayMode: 'adaptive',
        colorTheme: 'dark',
        locale: 'it'
      });
      document.getElementById('tradingview-widget-container').appendChild(script);
    }
  }, []);

  return (
    <div id="tradingview-widget-container" className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a href="https://it.tradingview.com/" rel="noopener noreferrer" target="_blank">
            <span className="text-transparent">Segui tutti i mercati su TradingView</span>
          </a>
        </div>
    </div>
  );
}

export default Footer;
