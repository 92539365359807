import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import goldFormation from '../assets/prezzoOro/stlle.jpg';
import chip from '../assets/prezzoOro/chip.jpg';
import logo from '../assets/logo/logo_icon.png';
import { Link } from 'react-router-dom';

function Scienza() {
  return (
    <>
      <Helmet>
        <title>Scienza dell'oro: formazione chimica e curiosità scientifiche | prezzo-oro.com</title>
        <meta name="description" content="Scopri la scienza dell'oro, dalla sua formazione chimica alle sue curiosità scientifiche. Approfondisci le sue proprietà atomiche, il suo ruolo nel sistema periodico e altro ancora." />
        <meta name="keywords" content="scienza dell'oro, formazione chimica, chimica degli elementi, proprietà atomiche, sistema periodico, metalli di transizione" />
        <meta name="author" content="PrezzoOro.com" />
        <meta property="og:title" content="Scienza dell'oro: formazione chimica e curiosità scientifiche | PrezzoOro.com" />
        <meta property="og:description" content="Scopri la scienza dell'oro, dalla sua formazione chimica alle sue curiosità scientifiche. Approfondisci le sue proprietà atomiche, il suo ruolo nel sistema periodico e altro ancora." />
        <meta property="og:image" content={goldFormation} />
        <meta property="og:url" content="https://prezzo-oro.com/scienza-dell-oro/" />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content="2024-04-20T10:30:00" />
        <meta property="article:author" content="prezzo-oro.com" />
        <meta property="article:section" content="Scienza dell'oro" />
        <meta property="article:tag" content="scienza dell'oro, formazione chimica, chimica degli elementi, proprietà atomiche, sistema periodico, metalli di transizione" />
      </Helmet>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-static-gold text-center mb-4">Scienza dell'oro: formazione chimica e curiosità scientifiche</h1>
          <p className="text-neutral-200 text-lg mb-8">
            L'oro è uno degli elementi più affascinanti della tavola periodica, con una serie di proprietà uniche che lo rendono un oggetto di studio per scienziati e ricercatori di tutto il mondo.
          </p>
          <h2 className="text-2xl font-bold text-slate-50 mb-4">Formazione chimica dell'oro</h2>
          <p className="mb-8 text-neutral-400">
            L'oro è un elemento chimico con il simbolo Au e il numero atomico 79. È classificato come metallo di transizione nella tavola periodica degli elementi. La sua formazione avviene principalmente attraverso i processi di fusione nucleare nelle stelle durante la nucleosintesi stellare.
          </p>
          <img src={goldFormation} alt="Formazione dell'oro" className="w-full h-auto mb-8 opacity-75" />
          <h2 className="text-2xl font-bold text-slate-50 mb-4">Proprietà atomiche dell'oro</h2>
          <p className="mb-8 text-neutral-400">
            L'oro ha una serie di proprietà atomiche interessanti che lo distinguono dagli altri elementi. Ha una massa atomica di circa 197 u e un raggio atomico di circa 0,144 nm. È noto per la sua elevata densità, conducibilità elettrica e resistenza alla corrosione.
          </p>
          <h2 className="text-2xl font-bold text-slate-50 mb-4">Ruolo nel sistema periodico</h2>
          <p className="mb-8 text-neutral-400">
            Nella tavola periodica, l'oro si trova nel gruppo 11 e nel periodo 6. È classificato come metallo di transizione e fa parte del blocco d. È uno dei metalli più preziosi e desiderati ed è ampiamente utilizzato in gioielleria, elettronica e altre applicazioni.
          </p>
          <img src={chip} alt="Tavola periodica con evidenziato l'oro" className="w-full h-auto mb-8 opacity-75" />
          <h2 className="text-2xl font-bold text-slate-50 mb-4">Curiosità scientifiche</h2>
          <p className="mb-8 text-neutral-400">
            Oltre alle sue proprietà chimiche e fisiche, l'oro ha una serie di curiosità scientifiche affascinanti. È stato utilizzato per secoli in alchimia e ha svolto un ruolo importante nella storia della scienza e della tecnologia.
          </p>
          <p className="mb-8 text-neutral-400">
            La scienza dell'oro è un campo ricco di scoperte e nuove frontiere. Continua a essere oggetto di studio e ricerca, con l'obiettivo di sfruttarne appieno le potenzialità e scoprire nuove applicazioni.
          </p>
        </div>
      </div>
      <footer className="text-center my-8">
        <Link to="/" className="text-neutral-100 flex items-center justify-center">
          <img src={logo} alt="Logo Prezzo Oro" className="mr-2 h-8 w-8" />
          Prezzo-Oro.com
        </Link>
      </footer>
    </>
  );
}

export default Scienza;
