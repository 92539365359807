import React, { useState } from 'react'
import KeyPadComponent from '../components/KeyPad';
import Navbar from '../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faX } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import logoShare from '../assets/logo/sherelogo.png';
import { Link } from 'react-router-dom';
import logo from '../assets/logo/logo_icon.png';



function GoldCalculatorPage() {
  const [showInfo, setShowInfo] = useState(false);
  const [modalFade, setModalFade] = useState('fadeIn');

  const handleOpenFixingPopup = () => {
    setShowInfo(true);
    setModalFade('fadeIn');
  }

  const handleCloseFixingPopup = () => {
    setModalFade('fadeOut');
    setTimeout(() => {
      setShowInfo(false);
    }, 1500);
  };

  return (
    <>
      <Helmet>
        <title>Calcolatrice Prezzo Oro: Calcola il Valore dell'Oro Usato | prezzo-oro.com</title>
        <meta name="description" content="Utilizza la nostra calcolatrice per determinare il valore dell'oro usato in base alla caratura e al peso. Calcola il prezzo dell'oro in modo rapido e preciso." />
        <meta name="keywords" content="calcolatrice oro, prezzo oro, oro usato, calcolatore valore oro, caratura oro, peso oro" />
        <meta name="author" content="prezzo-oro.com" />
        <meta property="og:title" content="Calcolatrice Prezzo Oro: Calcola il Valore dell'Oro Usato | prezzo-oro.com" />
        <meta property="og:description" content="Utilizza la nostra calcolatrice per determinare il valore dell'oro usato in base alla caratura e al peso. Calcola il prezzo dell'oro in modo rapido e preciso." />
        <meta property="og:image" content={logoShare} />
        <meta property="og:url" content="https://prezzo-oro.com/calcolatrice-oro/" />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content="2024-04-20T10:30:00" />
        <meta property="article:author" content="prezzo-oro.com" />
        <meta property="article:section" content="Calcolatrice Prezzo Oro" />
        <meta property="article:tag" content="calcolatrice oro, prezzo oro, oro usato, calcolatore valore oro, caratura oro, peso oro" />
      </Helmet>

      <Navbar/>
      <div className="flex items-center justify-center mt-16">
        <h1 className='uppercase text-slate-50 font-semibold text-2xl text-static-gold'>Calcolatrice Prezzo Oro</h1>     
        <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-gray-400 text-sm ml-1 cursor-pointer transition-all duration-500 mb-auto"
            onClick={handleOpenFixingPopup}
        /> 
      </div>
      <div className="flex items-center justify-center">
        <p className="text-neutral-300 text-lg mb-8 md:w-1/2 px-2">
          Utilizza la nostra calcolatrice per determinare il valore dell'oro usato in base alla caratura e al peso. Calcola il prezzo dell'oro in modo rapido e preciso.
        </p>   
      </div>
      <div className='flex justify-center'>
        <div className='w-full md:w-1/2'>
            <KeyPadComponent />
        </div>
      </div>
      <div className="grid grid-cols-6 mx-5">
        <div className="md:col-start-3 md:col-span-2 col-span-6 mt-6 mb-16 w-full">
          {/* <img src={img1} alt="lingotti d'oro" className="w-full h-48 object-cover object-center" /> */}
          <div className="p-4">
            <h2 className="uppercase text-slate-50 font-semibold text-2xl text-static-gold">Come Calcolare il Valore dell'Oro Usato</h2>
            <p className="text-slate-400 line-clamp-2 hover:line-clamp-none mb-6 leading-loose">Scopri come calcolare il valore dell'oro usato con questa guida dettagliata. Utilizza la nostra calcolatrice online per ottenere un'accurata stima del valore del tuo oro usato in pochi semplici passaggi.</p>
            <Link to="/calcolare-valore-oro-usato-guida-calcolatrice-online/" className="text-slate-200 border rounded px-4 py-3 hover:bg-slate-200 hover:text-black transition duration-300 cursor-pointer">
              Scopri di più
            </Link>
          </div>
        </div>
        
      </div>
      <footer className="text-center my-8">
          <Link to="/" className="text-neutral-100 flex items-center justify-center">
            <img src={logo} alt="Logo Prezzo Oro" className="mr-2 h-8 w-8" />
            Prezzo-Oro.com
          </Link>
        </footer>
      {showInfo && (
        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center text-center glassmorphism ${modalFade}`}>
          <div className={`bg-transparent p-8 relative w-auto md:w-1/3`}>
            <span className='absolute top-0 right-0 cursor-pointer p-3' onClick={handleCloseFixingPopup}>
              <FontAwesomeIcon
                icon={faX}
                className="text-slate-50 opacity-75"
              />
            </span>
            <p className={`text-slate-50 text-justify font-semibold text-xl mb-6 mt-10`}>Utilizza il nostro calcolatore per ottenere il prezzo dell'oro usato in tempo reale con 3 semplici passaggi:</p>
            <ul className='text-start text-slate-50'>
              <li className='mb-3'><b>Step 1: </b> Seleziona dalla tastiera numerica la grammatura dell'oro che desideri convertire in euro.</li>
              <li className='mb-3'><b>Step 2: </b> Seleziona la caratura dell'oro tra le opzioni disponibili.</li>
              <li className='mb-3'><b>Step 3: </b> Premi il tasto "Invio" per ricevere istantaneamente il prezzo in euro per grammo dell'oro usato.</li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default GoldCalculatorPage