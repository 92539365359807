import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { hydrate } from "react-dom";
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-KFGSW2VC',
};

AOS.init();
TagManager.initialize(tagManagerArgs);


const rootElement = document.getElementById("root");
const root = createRoot(rootElement); 


if (rootElement.hasChildNodes()) {
  hydrate(  
  <React.StrictMode>
    <App />
  </React.StrictMode>, rootElement);
} else {
  root.render(  
  <React.StrictMode>
    <App />
  </React.StrictMode>);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
