import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/Navbar';
import logo from '../../assets/logo/logo_icon.png';
import img1 from '../../assets/articles/2024/sell-gold.webp';
import img2 from '../../assets/articles/2024/banche-centrali.webp';



function OroRecord() {
  return (
    <>
      <Helmet>
        <title>Oro: Nuovo Record Oltre Ogni Previsione | prezzo-oro.com</title>
        <meta name="description" content="L'oro ha raggiunto nuovi massimi storici. Scopri le analisi e le previsioni su questo rally inaspettato." />
        <meta name="keywords" content="oro, record oro, quotazioni oro, investimento oro, analisi tecnica oro" />
        <meta name="author" content="PrezzoOro.com" />
        <meta property="og:title" content="Oro: Nuovo Record Oltre Ogni Previsione | PrezzoOro.com" />
        <meta property="og:description" content="L'oro ha raggiunto nuovi massimi storici. Scopri le analisi e le previsioni su questo rally inaspettato." />
        <meta property="og:url" content="https://prezzo-oro.com/articles/2024/oro-nuovo-record" />
        <meta property="og:image" content={img1} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content="2024-05-22T10:30:00" />
        <meta property="article:author" content="prezzo-oro.com" />
        <meta property="article:section" content="Oro" />
        <meta property="article:tag" content="oro, record oro, quotazioni oro, investimento oro, analisi tecnica oro" />
      </Helmet>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-static-gold text-center mb-4">
            ORO, UN NUOVO RECORD OLTRE OGNI PREVISIONE: È ORA DI VENDERE?
          </h1>
          <p className="text-neutral-200 text-lg mb-8 text-justify">
            L’oro ha aggiornato ancora una volta i massimi storici lunedì 20 maggio, nelle stesse ore in cui l’attenzione globale era concentrata sull’incidente mortale che ha coinvolto il premier iraniano Ebrahim Raisi (con le relative speculazioni su un possibile coinvolgimento dei servizi israeliani). L’asticella del bene rifugio per eccellenza è salita a 2.454,20 dollari l’oncia, per poi ridurre i guadagni in area 2.421 dollari nel pomeriggio. Negli ultimi tre mesi, l’oro ha guadagnato il 19,60%, in un contesto che per vari aspetti non avrebbe dovuto favorirlo.
          </p>
          <img src={img1} alt="Mercato dell'oro e dell'argento" className="w-full h-auto my-8 opacity-75" />
          <h2 className="text-2xl font-bold text-slate-50 mb-4">
            ORO, UN RALLY SUPERIORE ALLE PREVISIONI
          </h2>
          <p className="mb-8 text-neutral-400 text-justify">
            Di solito, la persistenza di rendimenti obbligazionari elevati avrebbe dovuto favorire l’acquisto di titoli come i Buoni del Tesoro USA che, al contrario dell’oro, pagano cedole periodiche. “A fine 2023 i mercati si aspettavano sei, se non sette tagli al costo del denaro da parte della Federal Reserve nel corso di quest’anno”, ha dichiarato a We Wealth l’analista di Swissquote ed esperto del mercato aureo, Carlo Alberto De Casa, “adesso le attese sono comprese fra 1 e 2 tagli”. Questo scenario avrebbe dovuto comprimere il prezzo dell’oro, che già a dicembre si trovava sopra quota 2.000 dollari, un livello storicamente elevato, rimasto impossibile da bucare per anni.
          </p>
          <p className="mb-8 text-neutral-400 text-justify">
            Non è l’unica anomalia di questo rally. Mentre l’oro inanellava record, “i principali indici azionari si sono mossi in rialzo e in questi giorni stanno facendo nuovi massimi”, ha dichiarato a We Wealth Eugenio Sartorelli, membro del consiglio della Società Italiana di Analisi Tecnica (Siat), “ciò smonta parzialmente l’ipotesi che si acquisti l’oro per una maggiore percezione del rischio, soprattutto in chiave geopolitica, dal momento che, normalmente, quando aumenta la percezione del rischio non si acquistano azioni con questa intensità”. Di norma, quando c’è euforia sulle azioni, gli investitori acquistano queste ultime riducendo l’oro in portafoglio.
          </p>
          <p className="mb-8 text-neutral-400 text-justify">
            Altra stranezza: “Il prezzo dell’oro, un bene che viene normalmente scambiato in dollari, diventa più attraente quando i valori del dollaro sono in discesa”, ha ricordato Sartorelli, “tuttavia è da fine dicembre che il Dollar Index è mediamente al rialzo”.
          </p>
          <h2 className="text-2xl font-bold text-slate-50 mb-4">
            ORO: IL RUOLO DEGLI ACQUISTI DELLE BANCHE CENTRALI E DELLA GEOPOLITICA
          </h2>
          <p className="mb-8 text-neutral-400 text-justify">
            Come mai, nonostante tutte queste sfide, l’oro ha continuato la sua crescita? In buona parte, anche gli analisti fiduciosi sull’oro, come lo stesso De Casa, sono rimasti sorpresi da questo poderoso rialzo. Non è da escludere, ha ipotizzato De Casa, che gli ultimi acquisti sull’oro possano essere in parte motivati dalla volontà di coprirsi dal rischio di una correzione degli indici azionari in una fase in cui hanno già guadagnato molta quota. Per Sartorelli, la combinazione di fattori che ha inciso sull’oro è particolarmente complessa: “rischi geopolitici, rischi finanziari in genere, scenari inflattivi/deflattivi e conseguenti politiche delle principali banche centrali”.
          </p>
          <img src={img2} alt="Mercato dell'oro e dell'argento" className="w-full h-auto my-8 opacity-75" />
          <p className="mb-8 text-neutral-400 text-justify">
            Uno dei fattori che da qualche tempo sta sostenendo il prezzo dell’oro è “la domanda di acquisto delle banche centrali, pari a oltre mille tonnellate sia nel 2022 sia nel 2023”, ha affermato De Casa. Dello stesso avviso è anche Sartorelli, citando soprattutto le banche centrali di Cina, India e Turchia. “Questo elemento aiuta a spiegare il surplus rialzista dell’oro che fa nuovi massimi”, ha aggiunto Sartorelli, “difficile comunque identificare i reali motivi di tanto interesse da parte di queste banche centrali per l’oro”.
          </p>
          <h2 className="text-2xl font-bold text-slate-50 mb-4">
            VENDERE ADESSO? L’ANALISI TECNICA NON LO SUGGERISCE
          </h2>
          <p className="mb-8 text-neutral-400 text-justify">
            Per quanto riguarda l’analisi dei grafici, “l’oro sta avendo una nuova spinta al rialzo da inizio maggio”, ha osservato il consigliere di Siat, “come potenziali livelli raggiungibili, dove vi possono essere prese di beneficio, vi sono valori tra 2.500 e 2.600 dollari; in caso di forza sostenuta (difficile da ipotizzare adesso) vi è 2.800 dollari e sino a 3.000 dollari”. In sintesi, l’analisi tecnica indica una forza a breve termine per l’oro che non deporrebbe a favore di una vendita. La scelta di prendere beneficio per ragioni tattiche, dunque, non sarebbe attualmente quella con maggior potenziale di successo.
          </p>
          <p className="mb-8 text-neutral-400 text-justify">
            Oltre all’oro, l’argento sta correndo a passo ancor più spedito, complice la sua maggiore volatilità rispetto al metallo giallo: negli ultimi tre mesi la performance è vicina al 37% e, questo lunedì, ha raggiunto un nuovo massimo dal 2012 a 32,75 dollari. “È una domanda guidata in proporzione maggiore dal suo utilizzo industriale”, ha concluso De Casa, “e si prevede una crescita anche nei prossimi anni”.
          </p>
        </div>
      </div>
        <footer className="text-center my-8">
            <p className="text-neutral-100 flex items-center justify-center">
                <img src={logo} alt="Logo Prezzo Oro" className="mr-2 h-8 w-8" />
                Pubblicato 22.05.2024 18:00
            </p>
        </footer>
    </>
  );
}

export default OroRecord;
