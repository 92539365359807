import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { RxHamburgerMenu } from 'react-icons/rx';
import logo from '../assets/logo/logo1.png';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="flex items-center justify-between px-4 py-6 lg:px-40" style={{backgroundColor:"#272727"}}>
            <Link to="/">
                <img src={logo} alt="logo" className="w-36" />
            </Link>
            <div className="flex items-center">
                <div className="hidden lg:block ml-4">
                    <nav className="flex space-x-4">
                        <Link to="/" className="text-slate-50 font-semibold hover:text-gray-300">Home Page</Link>
                        <Link to="/calcolatrice-oro" className="text-slate-50 font-semibold hover:text-gray-300">Prezzo Oro Usato</Link>
                        <Link to="/acquisto-vendita-oro-italia" className="text-slate-50 font-semibold hover:text-gray-300">Acquisto / Vendita</Link>
                        <Link to="/storia-del-oro" className="text-slate-50 font-semibold hover:text-gray-300">La storia dell'Oro</Link>
                        <Link to="/scienza-dell-oro" className="text-slate-50 font-semibold hover:text-gray-300">Scienza dell'Oro</Link>
                        <Link to="/ultime-notizie-metalli-preziosi" className="text-slate-50 font-semibold hover:text-gray-300">Ultime Notizie</Link>
                    </nav>
                </div>
            </div>
            <div className="lg:hidden">
                <button onClick={handleMenuOpen} className="text-slate-50" type='button'>
                    <RxHamburgerMenu className="w-6 h-6" />
                </button>
            </div>
            {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-40" style={{backgroundColor:"#272727"}}>
                        <button onClick={handleMenuClose} className="absolute top-4 right-4 text-slate-50">
                            <IoClose className="w-6 h-6" />
                        </button>
                    <div className="relative flex flex-col items-center">
                        <nav className="flex flex-col space-y-4">
                            <Link to="/" className="text-slate-50 font-semibold hover:text-gray-300">Home Page</Link>
                            <Link to="/calcolatrice-oro" className="text-slate-50 font-semibold hover:text-gray-300">Prezzo Oro Usato</Link>
                            <Link to="/acquisto-vendita-oro-italia" className="text-slate-50 font-semibold hover:text-gray-300">Acquisto / Vendita</Link>
                            <Link to="/storia-del-oro" className="text-slate-50 font-semibold hover:text-gray-300">La storia dell'Oro</Link>
                            <Link to="/scienza-dell-oro" className="text-slate-50 font-semibold hover:text-gray-300">Scienza dell'Oro</Link>
                            <Link to="/ultime-notizie-metalli-preziosi" className="text-slate-50 font-semibold hover:text-gray-300">Ultime Notizie</Link>
                        </nav>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;
